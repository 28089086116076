import React from "react";
import { Card, Typography, Grid } from '@mui/material';
import upgradeSubscriptionImage from "src/assets/upgrade-subscription-image.png";
import { IconContext } from 'react-icons';
import { MdArrowForwardIos } from 'react-icons/md';

const UpgardeSubscriptionTile = () => {
    return (
        <>
            <Card className="plans_tile">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" component="div" gutterBottom className="upgrade_plans_tile_title">Upgrade Your Account to PRO</Typography>
                        <Typography variant="subtitle1" component="div" gutterBottom className="upgrade_plans_tile_subtitle">You get a lot more features when upgrading to a PRO plan.</Typography>
                        <br />
                        <Typography variant="subtitle1" component="div" gutterBottom className="manage_user_title">Manage your users better</Typography>
                        <br />
                        <button className="get_started_button pointer">Get started today</button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} className="align_center">
                            <Grid item xs={12} md={8}>
                                <img src={upgradeSubscriptionImage} alt="Upgrade Subscription" className="upgrade_subscription_image" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="arrow_icon_container flex_center pointer">
                                    <IconContext.Provider value={{ color: "#1A75FF", size: "35" }}>
                                        <MdArrowForwardIos />
                                    </IconContext.Provider>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default UpgardeSubscriptionTile;