import React from "react";
import { Button, TextField } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import {
  teamInfo,
  AddSnackInfo,
  teamdata,
  teameditstore,
} from "../../slices/Team";
import { RootState } from "../../store";
import { Snackbar } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  col: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem",
    marginTop: "1rem",
  },
  addbutton: {
    backgroundColor: "#0096FF",
    padding: "5px 25px",
    fontWeight: "700",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#0096FF",
      color: "#ffffff",
    },
  },
  nobutton: {
    backgroundColor: "#F76E11",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#F76E11",
      color: "#ffffff",
    },
  },
  center: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    padding: "15px 24px",
    borderTop: "1px solid rgba(34, 51, 84, 0.12)"
  },
}));

// SnackBar Code
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddTeam() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { team, Addsnack } = useSelector((state: RootState) => state.Team);

  // Add Data Dialog
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    const emptydata = {
      name: "",
      email: "",
      status: "",
    };
    dispatch(teameditstore(emptydata));
  };

  const handleSubmit = () => {
    dispatch(AddSnackInfo(true));
    handleClose();
    const data = {
      id: Date.now(),
      name: team.name.replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
      email: team.email,
      status: "Pending",
    };
    dispatch(teamdata(data));
    const emptydata = {
      name: "",
      email: "",
      status: "",
    };
    dispatch(teameditstore(emptydata));
  };

  const addTeam = (event: { target: { name: any; value: any } }) => {
    if (event.target.name === "name") {
      dispatch(teamInfo({ key: event.target.name, value: event.target.value }));
    } else {
      dispatch(teamInfo({ key: event.target.name, value: event.target.value }));
    }
  };

  // Button Enable Disable Validation

  const validateEmail = (email) => {
    return email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
  }

  if (team.name.length >= 3 && validateEmail(team.email)) {
    dispatch(teamInfo({ key: "button", value: false }));
  } else {
    dispatch(teamInfo({ key: "button", value: true }));
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Addsnack}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(AddSnackInfo(false));
        }}
      >
        <Alert className="snackbar_success" severity="success">
          Member Invited Successfully
        </Alert>
      </Snackbar>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        className="add_team_member_button"
      >
        <AddOutlinedIcon />Add Team Member
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="invite_member_dialog"
      >
        <DialogTitle className="invite_member_dialog_title justify_space_between align_center">
          <div>
            <div className="invite_member_dialog_heading font_bold">Invite New Member</div>
            <div className="invite_member_dialog_subheading">Fill in the fields below to invite a new member</div>
          </div>
        </DialogTitle>
        <DialogContent>
          <form>
            <Box className={classes.col}>
              <div>
                <InputLabel className="table_row_color invite_member_dialog_label">Name</InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={(e) => addTeam(e)}
                  name="name"
                  value={team.name}
                  fullWidth
                  placeholder="Please enter name here..."
                />
              </div>
              <div>
                <InputLabel className="table_row_color invite_member_dialog_label">Email</InputLabel>
                <TextField
                  variant="outlined"
                  size="small"
                  onChange={(e) => addTeam(e)}
                  name="email"
                  value={team.email}
                  fullWidth
                  placeholder="Please enter email here..."
                />
              </div>
            </Box>
          </form>
        </DialogContent>
        <DialogActions className={classes.center}>
          <Button onClick={handleClose} className="invite_cancel_button font_bold"> Cancel</Button>
          <Button
            onClick={handleSubmit}
            autoFocus
            className={classes.addbutton}
            style={{
              backgroundColor: team.button ? "#666666" : "#1A75FF",
            }}
            disabled={team.button}
          >
            Invite Member
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
