import React from 'react';
import MyPlans from './myplans';
import "./Plans.css";

const Plans = () => {
  return (
    <div className="plans_container">
        <MyPlans />
    </div>
  );
}

export default Plans;
