import React from 'react';
import { Typography } from '@mui/material';
import ManageSubscriptionCard from "./ManageSubscriptionCard";

const ManageSubscription = () => {
    return (
        <div className="plans_container">
            <Typography variant="h1" component="div" gutterBottom>Manage Subscription</Typography>
            <Typography className="sub_title" variant="subtitle2" gutterBottom component="div">Create API Keys for generating access tokens for your application.</Typography>
            <br /><br />
            <ManageSubscriptionCard />
        </div>
    )
}

export default ManageSubscription;