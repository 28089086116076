import { createSlice } from "@reduxjs/toolkit";
import axios from '../utils/axios';
import { SuccessSnackbar, FailureSnackbar } from "src/slices/Snackbar";

interface RecordingData {
    id: string,
    roomId: string,
    roomName?: string,
    title?: string,
    context?: {},
    uri: string,
    createdDate: string,
    duration: number,
    startTime: string,
    endTime: string,
    userId?: string,
    appId: string
}

interface ApplicationData {
    name: string,
    expiry: string,
    appId: string,
    createdDate: string
}

interface Recordings {
    recordingData: RecordingData[],
    applicationData: ApplicationData[],
    selectedApplication: string,
    selectedRoom: string,
    buttonVisibility: boolean,
    paginationData: any,
    showLoader: boolean
}

const initialState: Recordings = {
    recordingData: [],
    applicationData: [],
    selectedApplication: "",
    selectedRoom: "",
    buttonVisibility: true,
    paginationData: {
        page: 0,
        per_page: 5,
        page_count: 0
    },
    showLoader: false
};


const slice = createSlice({
    name: "recordings",
    initialState,

    reducers: {
        // Single Payload
        RecordingDataList(state, action) {
            state.recordingData = action.payload;
        },
        AppicationData(state, action) {
            state.applicationData = action.payload;
        },
        SelectedApplication(state, action) {
            state.selectedApplication = action.payload;
        },
        SelectedRoom(state, action) {
            state.selectedRoom = action.payload;
        },
        ButtonVisibility(state, action) {
            state.buttonVisibility = action.payload;
        },
        PaginationData(state, action) {
            state.paginationData = action.payload;
        },
        ShowLoader(state, action) {
            state.showLoader = action.payload;
        }
    },
});

const BASE_URL = process.env.REACT_APP_API_URL;

export const GetAppicationData = () => async (dispatch) => {
    try {
        const applicationData = await axios.get(`${BASE_URL}/api/v1/application/`);
        if (!applicationData.data?.status) {
            throw new Error(applicationData.data?.message);
        }
        dispatch(slice.actions.AppicationData(applicationData.data.data));
        dispatch(SuccessSnackbar({ message: applicationData.data?.message, open: true }));
    } catch (error) {
        dispatch(FailureSnackbar({ message: error?.message || error, open: true }))
    }
}

export const SelectedApplication = (data) => async (dispatch) => {
    dispatch(slice.actions.SelectedApplication(data));
}

export const SelectedRoom = (data) => async (dispatch) => {
    dispatch(slice.actions.SelectedRoom(data));
}

export const ButtonVisibility = (data) => async (dispatch) => {
    dispatch(slice.actions.ButtonVisibility(data));
}

export const RecordingDataList = (data) => async (dispatch) => {
    try {
        dispatch(ShowLoader(true));
        const recordingData = await axios.get(`${BASE_URL}/api/v1/recordings/`, {
            params: {
                app_id: data.appId,
                room_id: data.roomId,
                page: data.page,
                per_page: data.per_page,
                sort_mode: data.sort_mode
            }
        });
        if (!recordingData.data?.status) {
            throw new Error(recordingData.data?.message);
        }
        dispatch(slice.actions.PaginationData(recordingData.data.meta));
        dispatch(slice.actions.RecordingDataList(recordingData.data.data));
        dispatch(SuccessSnackbar({ message: recordingData.data?.message, open: true }));
    } catch (error) {
        dispatch(FailureSnackbar({ message: error?.message || error, open: true }))
    } finally {
        dispatch(ShowLoader(false));
    }
}

export const ClearRecordingData = () => async (dispatch) => {
    dispatch(slice.actions.RecordingDataList([]));
}

export const ClearPaginationData = () => async (dispatch) => {
    dispatch(slice.actions.PaginationData({
        page: 0,
        per_page: 5,
        page_count: 0
    }));
}
export const ShowLoader = (data) => async (dispatch) => {
    dispatch(slice.actions.ShowLoader(data))
}


export const reducer = slice.reducer;

export default slice;