import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeCreator } from './base';
import {StyledEngineProvider } from '@mui/material';

// eslint-disable-next-line 
export const ThemeContext = React.createContext((themeName: string): void => {});

const ThemeProviderGlobal: React.FC = (props) => {
  const curThemeName = 'PureLightTheme';
  const [themeName, setCurrentThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
   // localStorage.setItem('appTheme',themeName);
    setCurrentThemeName(themeName);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeContext.Provider value={setThemeName}>
            <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
        </StyledEngineProvider >
    </>
  );
};

export default ThemeProviderGlobal;
