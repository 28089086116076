import React from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Grid, Button } from '@mui/material';
import manageSubscriptionImage from "src/assets/manage-subscription-image.png";
import { IconContext } from 'react-icons';
import { AiOutlineArrowRight } from 'react-icons/ai';

const ManageSubscriptionTile = () => {
    return (
        <>
            <Card className="plans_tile">
                <Typography variant="h4" component="div" gutterBottom className="manage_plans_tile_title">IS YOUR BUSINESS GROWING?</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <img src={manageSubscriptionImage} alt="Manage Subscription" className="manage_subscription_image"/>
                    </Grid>
                    <Grid item xs={12} md={6} className="align_center manage_subscription_button_container">
                        <Button component={Link} to="/manage-subscription" className="flex_center manage_subscription_button pointer">
                            Manage Subscriptions
                            <IconContext.Provider value={{ color: "white", size: "22" }}>
                                <AiOutlineArrowRight />
                            </IconContext.Provider>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    )
}

export default ManageSubscriptionTile;