import React from 'react';
import { Typography, Grid } from '@mui/material';
import ActiveSubscriptionTile from './ActiveSubscriptionTile';
import ManageSubscriptionTile from './ManageSubscriptionTile';
import UpgardeSubscriptionTile from './UpgardeSubscriptionTile';


const MyPlans = () => {

    return (
        <div>
            <Typography variant="h1" component="div" gutterBottom>My Plans</Typography>
            <Typography className="sub_title" variant="subtitle2" gutterBottom component="div">Create API Keys for generating access tokens for your application.</Typography>
            <br /><br />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}><ActiveSubscriptionTile /></Grid>
                <Grid item xs={12} md={6}><ManageSubscriptionTile /></Grid>
            </Grid>
            <br />
            <Grid container spacing={0}>
                <Grid item xs={12} md={10}><UpgardeSubscriptionTile /></Grid>
            </Grid>

        </div>
    )

}

export default MyPlans;