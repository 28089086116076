import React from "react";
import { Card, Typography, Grid } from '@mui/material';
import { IconContext } from 'react-icons';
import { BiMessageError } from 'react-icons/bi';


const ActiveSubscriptionTile = () => {
    return (
        <>
            <Card className="plans_tile">
                <Typography variant="h4" component="div" gutterBottom className="active_plans_tile_title">ACTIVE SUBSCRIPTION PLAN</Typography>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2} className="align_center">
                            <Grid item xs={12} md={6}>
                                <div className="message_icon_container flex_center">
                                    <IconContext.Provider value={{ color: "white", size: "35" }}>
                                        <BiMessageError />
                                    </IconContext.Provider>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h1" component="div" gutterBottom className="text_dev">DEV</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                </Grid>
                <br />
                <div className="view_subscription_plan" style={{marginTop: "15px"}}>
                    <Typography className="view_subscription_plan_link pointer" variant="subtitle2" gutterBottom component="span">See subscription plans </Typography>
                    <Typography className="view_subscription_plan_text" variant="subtitle2" gutterBottom component="span">which suites you the best</Typography>
                </div>
            </Card>
        </>
    )
}

export default ActiveSubscriptionTile;