import * as React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Grid, Typography, DialogContent, DialogContentText } from "@mui/material";
import { Box } from "@mui/system";
import AddTeam from "./AddTeam";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { deleteData, DeleteSnackInfo, ReInviteSnackInfo } from "../../slices/Team";

// Snakbar code
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 30;

export default function TeamRegister() {
  const dispatch = useDispatch();
  const { allData, reInvitesnack } = useSelector((state: RootState) => state.Team);


  // Edit Data
  const { Deletesnack } = useSelector((state: RootState) => state.Team);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // Create table id
  const [storeId, setStoreId] = React.useState<number>();
  const [rowData, setRowData] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    setStoreId(row.id);
    setAnchorEl(event.currentTarget);
    setRowData(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Table Row Delete Code
  const handleDelete = (e: any) => {
    dispatch(DeleteSnackInfo(true));
    handleClose();
    const data = allData.filter((value) => value.id !== storeId);
    dispatch(deleteData(data));
  };
  // Delete Button Confirmation Box
  const [deleteOpen, setOpen] = React.useState(false);
  const handleClickOpenConfirmation = () => {
    setOpen(true);
  };
  const handleCloseDeleteButton = () => {
    setOpen(false);
    handleClose();
  };

  const InvitationOpen = () => {
    handleClose();
    dispatch(ReInviteSnackInfo(true));
  };

  const getNameInitials = (name) => {
    const nameArray = name.split(' ');
    const nameInitials = [];
    for (let i = 0; i <= 1; i++) {
      if (nameArray[i])
        nameInitials.push(nameArray[i][0]);
    }
    return nameInitials;
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Deletesnack}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(DeleteSnackInfo(false));
        }}
      >
        <Alert className="snackbar_failure_deletion" severity="success">
          Member Deleted Successfully
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={reInvitesnack}
        autoHideDuration={2000}
        onClose={() => {
          dispatch(ReInviteSnackInfo(false));
        }}
      >
        <Alert className="snackbar_success" severity="success">
          Member Re-invited Successfully
        </Alert>
      </Snackbar>
      <Grid container>
        <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
          <TableContainer component={Paper} sx={{ mt: "2rem" }} className="my_team_table">
            <Box className="add_team_conatiner align_center">
              <Typography className="table_row_color font_bold">My Team</Typography>
              <AddTeam />
            </Box>
            <Table aria-label="simple table">
              <TableHead className="table_header">
                <TableRow>
                  <TableCell className="table_header_data">Name</TableCell>
                  <TableCell className="table_header_data">Email</TableCell>
                  <TableCell className="table_header_data">Status</TableCell>
                  <TableCell className="table_header_data" align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allData &&
                  allData.map((row) => (
                    <TableRow key={row.name} className="table_rows" >
                      <TableCell component="th" scope="row" className=" table_row_color table_row_data">
                        <div className="align_center">
                          <div className="table_row_name flex_center">{getNameInitials(row.name)}</div>
                          <div>
                            <div>{row.name}</div>
                            <div className="last_admitted_text">Admitted 3 days ago</div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="table_row_color">{row.email}</TableCell>
                      <TableCell className="table_row_data">
                        {row.status === "Active" && <span className="active_user_button">{row.status}</span>}
                        {row.status === "Pending" && <span className="pending_user_button">{row.status}</span>}
                      </TableCell>
                      <TableCell className="table_more_icon table_row_data" align="right">
                        <IconButton
                          aria-label="more"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {open && rowData &&
            <Menu
              className="table_more_option_container"
              id="long-menu"
              key={rowData.id}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4,
                  width: "25ch",
                  marginLeft: "-5rem",
                },
              }}
            >
              {rowData.status !== 'Active' &&
                <MenuItem onClick={InvitationOpen} className="table_more_option resend_invitation justify_center">
                  Resend Invitation
                </MenuItem>
              }
              
              < MenuItem onClick={() => handleClickOpenConfirmation()} className="table_more_option justify_center">
                Remove Member
              </MenuItem>
              <Dialog
                open={deleteOpen}
                onClose={handleCloseDeleteButton}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="delete_member_dialog"
              >
                <DialogTitle id="alert-dialog-title" className="justify_end" >
                  <Button onClick={handleCloseDeleteButton} className="cancel_delete_member_button justify_end">
                    <CloseOutlinedIcon />
                  </Button>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText className="delete_member_content font_bold">
                    Are you sure you want to delete this member?
                  </DialogContentText>
                </DialogContent>
                <br />
                <DialogActions className="delete_member_dialog_action">
                  <Button
                    onClick={handleDelete}
                    className="delete_member_button"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Menu>
          }
        </Grid>
      </Grid>
    </>
  );
}
