import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios';


const tableData=[
  {
  id: 1,
  name: "Vasudha Nayak",
  email: "vasudha@gmail.com",
  status: "Active"
},
{
  id: 2,
  name: "Aditi",
  email: "aditi@gmail.com",
  status: "Pending"
}
];

interface allData {
  id: number;
  name: string;
  email: string;
  status: string;
}
interface Team {
  team: {
    id: number;
    name: string;
    email: string;
    status: string;
    button: boolean;
  };
  Addsnack: boolean;
  reInvitesnack: boolean;
  Deletesnack: boolean;
  allData: allData[];
}

const initialState: Team = {
  team: {
    id: Math.random(),
    name: "",
    email: "",
    status: "",
    button: true
  },
  Addsnack: false,
  reInvitesnack: false,
  Deletesnack: false,
  allData: tableData,
};

const slice = createSlice({
  name: "Team",
  initialState,
  reducers: {
    // Single Payload
    teamdata(state: any, action) {
      state.team[action.payload.key] = action.payload.value;
    },

    teamdata1(state: any, action) {
      state.team = action.payload;
    },

    teamdatapush(state, action) {
      state.allData.push(action.payload);
    },

    deleteStoreData(state, action) {
      state.allData = action.payload;
    },

    teamEdit(state, action) {
      state.allData = action.payload;
    },

    Addsnack(state: any, action) {
      state.Addsnack = action.payload;
    },
    reInvitesnack(state: any, action) {
      state.reInvitesnack = action.payload;
    },
    Deletesnack(state:any, action){
      state.Deletesnack = action.payload;
    }
  },
});

export const teamInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.teamdata(data));
};

export const teamdata = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.teamdatapush(data));
};

export const teamEdit = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.teamEdit(data));
};
export const deleteData = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.deleteStoreData(data));
};

export const teameditstore = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.teamdata1(data));
};

export const AddSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.Addsnack(data));
};

export const ReInviteSnackInfo = (data: any) => async (dispatch: any) => {
  dispatch(slice.actions.reInvitesnack(data));
};

export const DeleteSnackInfo = (data:any) => async (dispatch: any) => {
  dispatch(slice.actions.Deletesnack(data));
}

export const reducer = slice.reducer;
export default slice;
