import React from 'react';
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import { ClearSnackbar } from "../../slices/Snackbar";
import { RootState } from "../../store";

const CustomSnackbar = () => {

    const dispatch = useDispatch();
    const { snackbarMessage, snackbarOpen, snackbarStatus } = useSelector((state: RootState) => state.Snackbar);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    return (
        <>
            {
                snackbarOpen && snackbarStatus === "success" && <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={() => {
                        dispatch(ClearSnackbar());
                    }}
                >
                    <Alert className="snackbar_success" severity="success">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            }
            {
                 snackbarOpen && snackbarStatus === "failure" && 
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={() => {
                        dispatch(ClearSnackbar());
                    }}
                >
                    <Alert className="snackbar_failure_deletion" severity="error">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
             }
        </>
    )
}

export default CustomSnackbar;