import React from "react";
function CloseButtonDialog({ props }) {
  return (
    <>
      
      <svg
        width={props.width}
        height={props.height}
        viewBox="0 0 52 52" fill="green" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_936_6676)">
        <circle cx="26" cy="26" r="16" fill="#FF5A5A"/>
        </g>
        <g clipPath="url(#clip0_936_6676)">
        <path d="M26.5 26L32.04 20.44C32.18 20.3 32.18 20.08 32.04 19.94C31.9 19.8 31.68 19.8 31.54 19.94L26 25.5L20.44 19.96C20.3 19.82 20.08 19.82 19.94 19.96C19.8 20.1 19.8 20.32 19.94 20.46L25.5 26L19.96 31.56C19.82 31.7 19.82 31.92 19.96 32.06C20.02 32.12 20.12 32.16 20.2 32.16C20.28 32.16 20.38 32.12 20.44 32.06L26 26.5L31.54 32.06C31.6 32.12 31.7 32.16 31.78 32.16C31.86 32.16 31.96 32.12 32.02 32.06C32.16 31.92 32.16 31.7 32.02 31.56L26.5 26Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_d_936_6676" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_936_6676"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_936_6676" result="shape"/>
        </filter>
        <clipPath id="clip0_936_6676">
        <rect width="12.8" height="12.8" fill="white" transform="translate(19.6 19.6)"/>
        </clipPath>
        </defs>
        </svg>
    </>
  );
}

export default CloseButtonDialog;
