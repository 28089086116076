import React from 'react'
import Home from './Home'

function Index() {
    return (
        <>
            <Home/>
        </>
    )
}
export default Index
