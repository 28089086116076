import React from 'react';
function RewardIcon({ props }) {
    return (
        <>
            <svg width={props.width} height={props.width} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1_41)">
                    <path d="M5.189 25.136V34.181C5.194 34.6355 5.37802 35.0698 5.70114 35.3895C6.02426 35.7093 6.46042 35.8888 6.915 35.889H17.461V25.136H5.189ZM20.539 35.889H31.085C31.5396 35.8888 31.9757 35.7093 32.2989 35.3895C32.622 35.0698 32.806 34.6355 32.811 34.181V25.136H20.54V35.89L20.539 35.889ZM34.2 9.78599H30.747C31.1006 9.06774 31.2867 8.27858 31.291 7.47799C31.2862 6.05292 30.7168 4.68785 29.7074 3.68185C28.6981 2.67586 27.3311 2.11098 25.906 2.11099C24.4171 2.18153 22.9786 2.67184 21.7566 3.52528C20.5345 4.37871 19.5789 5.56046 19 6.93399C18.421 5.56053 17.4653 4.37885 16.2433 3.52543C15.0213 2.672 13.5828 2.18165 12.094 2.11099C10.6692 2.11151 9.30261 2.67659 8.29352 3.6825C7.28442 4.68841 6.71503 6.05318 6.71 7.47799C6.714 8.27799 6.9 9.06799 7.254 9.78599H3.8C2.862 9.78599 2.111 10.893 2.111 12.244V19.619C2.111 20.97 2.861 22.078 3.8 22.078H17.461V9.78599H20.539V22.078H34.2C35.138 22.078 35.889 20.97 35.889 19.619V12.244C35.889 10.894 35.139 9.78599 34.2 9.78599ZM12.094 9.78599C11.7746 9.81506 11.4526 9.77724 11.1487 9.67494C10.8447 9.57265 10.5654 9.40812 10.3285 9.19185C10.0917 8.97559 9.90251 8.71233 9.77308 8.41888C9.64365 8.12543 9.57681 7.80822 9.57681 7.48749C9.57681 7.16677 9.64365 6.84956 9.77308 6.55611C9.90251 6.26265 10.0917 5.9994 10.3285 5.78313C10.5654 5.56687 10.8447 5.40234 11.1487 5.30004C11.4526 5.19774 11.7746 5.15992 12.094 5.18899C14.722 5.18899 16.204 7.89099 16.936 9.78599H12.094ZM25.906 9.78599H21.064C21.796 7.90999 23.279 5.18899 25.906 5.18899C26.2254 5.15992 26.5474 5.19774 26.8513 5.30004C27.1553 5.40234 27.4346 5.56687 27.6715 5.78313C27.9083 5.9994 28.0975 6.26265 28.2269 6.55611C28.3563 6.84956 28.4232 7.16677 28.4232 7.48749C28.4232 7.80822 28.3563 8.12543 28.2269 8.41888C28.0975 8.71233 27.9083 8.97559 27.6715 9.19185C27.4346 9.40812 27.1553 9.57265 26.8513 9.67494C26.5474 9.77724 26.2254 9.81506 25.906 9.78599Z" fill="#3087EC" />
                </g>
                <defs>
                    <clipPath id="clip0_1_41">
                        <rect width="38" height="38" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}

export default RewardIcon;
