import { errorMessage } from "src/constants/string";
const helper = {
    dateFormate: dateTime => {
        return dateTime ? (((new Date(dateTime).getDate()) < 10 ? '0' : '') + new Date(dateTime).getDate() + '/' +
            ((1 + (parseInt(Number(new Date(dateTime).getMonth())))) < 10 ? "0" : "") + (1 + (parseInt(Number(new Date(dateTime).getMonth())))) + '/' +
            (new Date(dateTime).getFullYear())) : ''
    },
    validateName: text => {
        const textMatch = text.match(/^([A-Za-z\s]){3,64}$/);
        return text
            ? textMatch ? "" : errorMessage.validateName
            : errorMessage.validateName;
    },

    validateAlphabetsType: num => num.match(/^[a-zA-Z]+$/),
    dateWithMonth: date => {
        return date ?
            new Date(date).toISOString().slice(0, 10).split('-').join('-')
            : ''
    },
    cardNumber: text => {
        const textMatch = text.match(/^([0-9]){16,16}$/);
        return text
            ? textMatch ? "" : errorMessage.cardNumber
            : errorMessage.cardNumber;
    },
    expiration: text => {
        const textMatch = text.match(/^([0-9]){4,4}$/);
        return text
            ? textMatch ? "" : errorMessage.expiration
            : errorMessage.expiration;
    },
    cvv: text => {
        const textMatch = text.match(/^([0-9]){3,3}$/);
        return text
            ? textMatch ? "" : errorMessage.cvv
            : errorMessage.cvv;
    },
    postalCode: text => {
        const textMatch = text.match(/^([0-9]){3,10}$/);
        return text
            ? textMatch ? "" : errorMessage.postalCode
            : errorMessage.postalCode;
    },
    couponCode: text => {
        const textMatch = text.match(/^([0-9]){3,3}$/);
        return text
            ? textMatch ? "" : errorMessage.couponCode
            : errorMessage.couponCode;
    },
    validateNumberType: num => num.match(/^\d+$/),
    validateUrl: url => {
        // eslint-disable-next-line
        const expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        return !url.match(regex) ? errorMessage.validUrl : ''
    }
}

export default helper;