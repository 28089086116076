import React from "react";
function WebHookIcon({ props }) {
  return (
    <>
      <svg width={props.width} height={props.height} viewBox="0 0 275 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M102.692 60.6013C102.692 42.0941 117.602 27.0781 136.05 27.0781C148.591 27.0781 159.539 34.025 165.235 44.3563C166.056 45.9339 167.182 47.3302 168.548 48.463C169.913 49.5958 171.489 50.4422 173.184 50.9524C174.878 51.4626 176.657 51.6263 178.416 51.434C180.174 51.2416 181.877 50.697 183.423 49.8322C184.97 48.9674 186.329 47.7999 187.421 46.3983C188.512 44.9967 189.315 43.3894 189.781 41.671C190.246 39.9525 190.366 38.1576 190.133 36.3918C189.9 34.6259 189.319 32.9249 188.423 31.3888C183.237 21.9809 175.645 14.1422 166.436 8.68592C157.227 3.22966 146.735 0.354704 136.05 0.359399C128.189 0.352378 120.404 1.90527 113.14 4.92935C105.875 7.95342 99.273 12.3894 93.7112 17.9839C88.1495 23.5783 83.7368 30.2215 80.7253 37.534C77.7139 44.8465 76.1627 52.6849 76.1604 60.6013C76.1604 77.345 82.9701 92.4856 93.901 103.387L57.5708 167.405C52.0039 168.118 46.9144 170.936 43.3342 175.288C39.7539 179.639 37.9509 185.2 38.2906 190.841C38.6303 196.482 41.0873 201.782 45.1634 205.666C49.2396 209.55 54.63 211.728 60.2416 211.758H60.3655C64.0371 211.759 67.6512 210.839 70.8815 209.082C74.1118 207.324 76.8566 204.784 78.8681 201.691C80.8795 198.597 82.0944 195.048 82.4029 191.364C82.7115 187.679 82.104 183.975 80.6353 180.586L122.537 106.771C124.228 103.789 124.715 100.266 123.894 96.9324C123.074 93.5989 121.011 90.7116 118.133 88.8697C113.402 85.8498 109.506 81.675 106.807 76.7329C104.107 71.7908 102.692 66.2418 102.692 60.6013Z" fill="#3087EC" />
        <path d="M133.839 82.7428C128.186 82.1831 122.963 79.4536 119.255 75.1204C115.546 70.7873 113.636 65.1828 113.92 59.4694C114.205 53.756 116.662 48.372 120.782 44.434C124.902 40.496 130.369 38.306 136.05 38.3184H136.156C139.788 38.3162 143.364 39.2149 146.569 40.9351C149.773 42.6553 152.508 45.144 154.531 48.1814C156.554 51.2187 157.803 54.7112 158.167 58.35C158.532 61.9888 158 65.662 156.62 69.045L194.808 132.671C203.833 129.46 213.491 128.476 222.973 129.802C232.454 131.128 241.48 134.725 249.295 140.292C257.11 145.858 263.484 153.232 267.884 161.793C272.283 170.354 274.579 179.853 274.578 189.493C274.578 198.691 272.486 207.767 268.464 216.026C264.441 224.284 258.595 231.505 251.373 237.136C244.151 242.768 235.745 246.659 226.798 248.512C217.852 250.366 208.603 250.132 199.76 247.829C198.022 247.442 196.38 246.707 194.93 245.667C193.48 244.627 192.253 243.304 191.321 241.777C190.39 240.25 189.773 238.55 189.508 236.777C189.242 235.005 189.334 233.197 189.776 231.461C190.219 229.725 191.004 228.096 192.085 226.672C193.166 225.248 194.52 224.058 196.067 223.172C197.614 222.286 199.323 221.722 201.091 221.515C202.859 221.308 204.65 221.461 206.358 221.965C211.281 223.255 216.433 223.392 221.417 222.365C226.401 221.338 231.086 219.175 235.111 216.041C239.136 212.908 242.395 208.887 244.637 204.288C246.88 199.689 248.046 194.634 248.047 189.511C248.051 183.493 246.448 177.585 243.405 172.406C240.361 167.227 235.991 162.968 230.751 160.075C225.511 157.182 219.594 155.763 213.622 155.965C207.65 156.167 201.842 157.984 196.806 161.225C195.314 162.182 193.647 162.828 191.904 163.127C190.16 163.426 188.374 163.37 186.652 162.963C184.93 162.557 183.306 161.807 181.875 160.759C180.445 159.711 179.237 158.386 178.323 156.861L133.839 82.7428Z" fill="#3087EC" />
        <path d="M31.4109 172.767C32.2842 171.249 32.8521 169.572 33.0821 167.833C33.3121 166.094 33.1997 164.326 32.7513 162.631C32.3029 160.935 31.5273 159.346 30.4688 157.952C29.4103 156.558 28.0896 155.389 26.5822 154.509C23.5377 152.733 19.9172 152.247 16.5173 153.159C13.1173 154.071 10.2163 156.306 8.45248 159.372C3.20563 168.535 0.446083 178.927 0.451179 189.504C0.456275 200.081 3.22583 210.471 8.48151 219.629C13.7372 228.786 21.2938 236.39 30.392 241.674C39.4901 246.959 49.8092 249.739 60.3122 249.735C88.7891 249.735 112.667 229.713 118.699 202.852H196.877C200.928 208.267 207.348 211.758 214.583 211.758H214.706C220.57 211.758 226.194 209.413 230.34 205.237C234.486 201.061 236.816 195.398 236.816 189.493C236.816 183.588 234.486 177.924 230.34 173.749C226.194 169.573 220.57 167.227 214.706 167.227H214.583C207.348 167.227 200.928 170.736 196.895 176.133H106.919C103.401 176.133 100.026 177.541 97.5386 180.046C95.0508 182.552 93.6532 185.95 93.6532 189.493C93.6508 193.9 92.7859 198.264 91.1078 202.335C89.4296 206.406 86.9713 210.104 83.8732 213.217C80.7751 216.331 77.098 218.798 73.0522 220.48C69.0064 222.161 64.6713 223.023 60.2945 223.016C54.4539 223.017 48.7157 221.471 43.6556 218.533C38.5956 215.595 34.3916 211.369 31.4655 206.279C28.5394 201.188 26.994 195.412 26.9845 189.53C26.9749 183.648 28.5014 177.867 31.4109 172.767Z" fill="#3087EC" />
      </svg>
    </>
  );
}

export default WebHookIcon;