import SwiperCore, { Navigation, Pagination } from "swiper";
import { experimentalStyled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

SwiperCore.use([Navigation, Pagination]);

const TypeTextTitle = experimentalStyled(Typography)(
  () => `
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
    `
);

const Loader = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress />
        <TypeTextTitle>Loading</TypeTextTitle>
      </Box>
    </>
  );
};

export default Loader;
