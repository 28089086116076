import React from "react";
function CloseButton({ props }) {
  return (
    <>
      <svg width={props.width}
        height={props.height} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4777_3984)">
          <circle cx="26" cy="26" r="16" fill="#3087EC" />
        </g>
        <g clip-path="url(#clip0_4777_3984)">
          <rect width="12.8" height="12.8" transform="translate(19.6001 19.6001)" fill="#3087EC" />
          <path d="M26.5 26.0002L32.04 20.4402C32.18 20.3002 32.18 20.0802 32.04 19.9402C31.9 19.8002 31.68 19.8002 31.54 19.9402L26 25.5002L20.44 19.9602C20.3 19.8202 20.08 19.8202 19.94 19.9602C19.8 20.1002 19.8 20.3202 19.94 20.4602L25.5 26.0002L19.96 31.5602C19.82 31.7002 19.82 31.9202 19.96 32.0602C20.02 32.1202 20.12 32.1602 20.2 32.1602C20.28 32.1602 20.38 32.1202 20.44 32.0602L26 26.5002L31.54 32.0602C31.6 32.1202 31.7 32.1602 31.78 32.1602C31.86 32.1602 31.96 32.1202 32.02 32.0602C32.16 31.9202 32.16 31.7002 32.02 31.5602L26.5 26.0002Z" fill="white" />
        </g>
        <defs>
          <filter id="filter0_d_4777_3984" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4777_3984" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4777_3984" result="shape" />
          </filter>
          <clipPath id="clip0_4777_3984">
            <rect width="12.8" height="12.8" fill="white" transform="translate(19.6001 19.6001)" />
          </clipPath>
        </defs>
      </svg>

    </>
  );
}

export default CloseButton;
