import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Dialog, IconButton } from '@mui/material';
import CloseButton from 'src/assets/icons/CloseButton';

interface IModalConfirm {
    open?: boolean
    setOpen?: Function
    childrens?: any
    background?: string
    closeIconId?: string
    width: {
        xs: string
        sm: string
        md: string
        lg: string
        xl: string
    }
    height: {
        xs: string
        sm: string
        md: string
        lg: string
        xl: string
    }
    padding?: string
}


const CustomizedDialog = styled(Dialog)`
  & .MuiPaper-root.MuiPaper-elevation {
    overflow: visible;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width:auto;
  }
  width:auto;
}
`;

const DialogWrapper = styled(Box)`
width:inherit;
height:inherit;
// padding:15px 20px;
display:flex;
flex-direction:column;
align-items:flex-start;
justifyContent:center;
gap:10px;
`;


const CustomDialogBox: FC<IModalConfirm> = ({ childrens, open, setOpen, background, closeIconId, width, height, padding }) => {
    return (
        <CustomizedDialog
            id="custom"
            maxWidth={"lg"}
            fullWidth={true}
            sx={{
                backdropFilter: "blur(3px)",
                width: {
                    xs: width?.xs,
                    sm: width?.sm,
                    md: width?.md,
                    lg: width?.lg,
                    xl: width?.xl,
                },
                height: {
                    xs: height?.xs,
                    sm: height?.sm,
                    md: height?.md,
                    lg: height?.lg,
                    xl: height?.xl,
                },
                background: background
            }}
            // PaperProps={{
            //     sx: {
            //         width: {
            //             xs: width?.xs,
            //             sm: width?.sm,
            //             md: width?.md,
            //             lg: width?.lg,
            //             xl: width?.xl,
            //         },
            //         height: {
            //             xs: height?.xs,
            //             sm: height?.sm,
            //             md: height?.md,
            //             lg: height?.lg,
            //             xl: height?.xl,
            //         },
            //     },
            // }}
            open={open}
        >
            <DialogWrapper sx={{
                background: background,
                width: {
                    xs: width?.xs,
                    sm: width?.sm,
                    md: width?.md,
                    lg: width?.lg,
                    xl: width?.xl,
                },
                height: {
                    xs: height?.xs,
                    sm: height?.sm,
                    md: height?.md,
                    lg: height?.lg,
                    xl: height?.xl,
                },
                padding: padding
            }}>
                {/* close icon */}
                <IconButton
                    id={closeIconId}
                    onClick={() => setOpen(false)}
                    size="small"
                    disableRipple
                    disableFocusRipple
                    style={{
                        borderRadius: "50%",
                        display: "flex",
                        position: "absolute",
                        boxShadow: "none",
                        top: -26,
                        right: 4,
                        padding:0,
                        width: "52px",
                        height: "52px",
                        overflow: "hidden",
                    }}
                >
                    <CloseButton
                        props={{
                            width: "52px",
                            height: "52px",
                        }}
                    />
                </IconButton>
                {/* close icon */}
                {/* childrens */}
                {childrens}
                {/* childrens */}
            </DialogWrapper>

        </CustomizedDialog>
    );
};

export default CustomDialogBox;
