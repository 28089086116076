import React from 'react';
import { Typography } from '@mui/material';
import "./Team.css";
import TeamRegister from './MyTeam';

const Team = () => {
    return(
        <div className="my_team_container">
            <Typography variant="h1" component="div" className="my_team_heading">My Team</Typography>
            <br />
            <TeamRegister /> 
        </div>
    )
}

export default Team;